// Stile für die FAQ
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen


.js .tx-cyzfaq-pi1 {
	margin: 20px 0;
}

.js .tx-cyzfaq-pi1 p,
.js .tx-cyzfaq-pi1 ul,
.js .tx-cyzfaq-pi1 ul li {
	margin-top: 0;
}

.js .tx-cyzfaq-pi1 h3 {
	cursor: pointer;
	font-size: 125%;
	margin: 10px 0;
	padding-left: 16px;
}

.js .tx-cyzfaq-pi1 h3 img {
	margin: 0 5px 0 -16px;
}

.js .tx-cyzfaq-pi1 .content {
	display: none;
	padding: 0 0 10px 16px;
}

.js .tx-cyzfaq-pi1 .item {
	border-bottom: 1px solid #eaeaea;
}

.js .tx-cyzfaq-pi1 .item:last-child {
	border-bottom: none;
}

.no-js .tx-cyzfaq-pi1 h3 img {
	display: none;
}
