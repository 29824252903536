// Stile für die Umfrage
//
// @author Lars Beuse <lars@cyperfection.de>
// @version $Id$
// @package ludwigshafen


// ***** Hand mit Zeigefinger und Wrapper um Polls ***************************************************

.poll-wrapper {
	display: block;
	background: url(../../Public/Images/StyleSheets/finger.png) no-repeat;
	min-height: 101px;
	position: relative;
}


// ***** Positionierung der Liste rechts und die Liste mit Auswahmöglichkeiten ***********************

.poll-poll {
	display: block;
	float: right;
	margin-left: 120px;
}

.poll-choices {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.poll-choice {
	float: left;
	padding-top: 3px;
	width: 20px;
}

.poll-choice-label {
	@include line-height(18);
	display: block;
}



// ***** Senden ***************************************************************************************

.pollsubmit {
	@extend .form-submit;

    position: absolute;
	bottom: 0;
    right: 0;
}

// ***** Ergebnis Horizontal ***************************************************************************

.poll-choice-label-result {
    display: block;
    float: right;
}

.poll-result-img {
  padding-right: 10px;
}
.poll-result-img  img {
  height: 10px;
}

.poll-result-percentage {
	padding-right: 10px;
}

.poll-result-answer {
	padding: 0 10px 2px 0;
}

.poll-result-votes {
	padding-right: 10px;
	font-weight:bold;
}
