// Alle Stile für die Website
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen

@import "../Gulp/node_modules/bootstrap/scss/functions";
@import "../Gulp/node_modules/bootstrap/scss/variables";
@import "../Gulp/node_modules/bootstrap/scss/mixins";
@import "../Gulp/node_modules/bootstrap/scss/root";
//@import "../Gulp/node_modules/bootstrap/scss/reboot";
@import "../Gulp/node_modules/bootstrap/scss/type";
@import "../Gulp/node_modules/bootstrap/scss/images";
@import "../Gulp/node_modules/bootstrap/scss/code";
@import "../Gulp/node_modules/bootstrap/scss/grid";
@import "../Gulp/node_modules/bootstrap/scss/tables";
@import "../Gulp/node_modules/bootstrap/scss/forms";
@import "../Gulp/node_modules/bootstrap/scss/buttons";
@import "../Gulp/node_modules/bootstrap/scss/transitions";
@import "../Gulp/node_modules/bootstrap/scss/dropdown";
@import "../Gulp/node_modules/bootstrap/scss/button-group";
@import "../Gulp/node_modules/bootstrap/scss/input-group";
@import "../Gulp/node_modules/bootstrap/scss/custom-forms";
@import "../Gulp/node_modules/bootstrap/scss/nav";
@import "../Gulp/node_modules/bootstrap/scss/navbar";
@import "../Gulp/node_modules/bootstrap/scss/card";
@import "../Gulp/node_modules/bootstrap/scss/breadcrumb";
@import "../Gulp/node_modules/bootstrap/scss/pagination";
@import "../Gulp/node_modules/bootstrap/scss/badge";
@import "../Gulp/node_modules/bootstrap/scss/jumbotron";
@import "../Gulp/node_modules/bootstrap/scss/alert";
@import "../Gulp/node_modules/bootstrap/scss/progress";
@import "../Gulp/node_modules/bootstrap/scss/media";
@import "../Gulp/node_modules/bootstrap/scss/list-group";
@import "../Gulp/node_modules/bootstrap/scss/close";
@import "../Gulp/node_modules/bootstrap/scss/toasts";
@import "../Gulp/node_modules/bootstrap/scss/modal";
@import "../Gulp/node_modules/bootstrap/scss/tooltip";
@import "../Gulp/node_modules/bootstrap/scss/popover";
@import "../Gulp/node_modules/bootstrap/scss/carousel";
@import "../Gulp/node_modules/bootstrap/scss/spinners";
@import "../Gulp/node_modules/bootstrap/scss/utilities";
//@import "../Gulp/node_modules/bootstrap/scss/print";

.modal-header {
    font-weight: bold;
  }

// Normalize
@import "normalize";

// Variablen und Mixins
@import "variables";
@import "prefixes";

// Allgemeine Stile
@import "fonts";
@import "global";
@import "site";

// Die Formulardefinition muß vor den inhaltlichen Stilen eingebunden werden
// so daß diese dort überschrieben werden können!
@import "forms";
@import "fancybox";

// Inhaltliche Stile
@import "comments";
@import "doorteaser";
@import "emergency";
@import "entryteaser";
@import "events";
@import "faq";
@import "gridelements";
@import "imagegallery";
@import "inputmodule";
@import "leadstory";
@import "news";
@import "poll";
@import "search";
@import "sitemap";
@import "topicbox";
@import "weather";

@import "parsley";

@media print {
	@import "print";
}
